<template>
  <div class="row mt-5 mb-5 text-center">
    <div class="col">
      <h2>Links</h2>
    </div>
  </div>
  <div class="container" :style="device === 'mobile' ? '' : 'width:800px;'">
    <RefID />
  </div>
  <div class="row justify-content-center">
    <div class="col-10">
      <p class="fs-4 my-4 text-center">Link per vendere prodotti</p>
    </div>
    <div class="col-11 col-md-8 col-lg-6">
      <el-input
        v-for="item in conti"
        :key="item.pkConto"
        :model-value="item.url + '?ref=' + item.codice"
        class="input-url"
        :disabled="true"
      >
        <template #prepend v-if="device != 'mobile'"> URL </template>
        <template #append v-if="device != 'mobile'">
          <el-button
            @click="doCopy(item.url + '?ref=' + item.codice)"
            type="primary"
            ><i class="el-icon-link"></i> Copia</el-button
          >
        </template>
      </el-input>
    </div>
  </div>

    <div class="row justify-content-center" v-if="device === 'mobile'">
      <div class="col-5 text-center">
        <el-button
        v-for="item in conti"
        :key="item.pkConto"
          size="medium"
          @click="doCopy(item.url + '?ref=' + item.codice)"
          type="primary"
          ><i class="el-icon-link"></i> Copia</el-button
        >
      </div>
    </div>

  <div class="row justify-content-center">
    <p class="fs-4 my-4 text-center">Link per affiliazione</p>
    <div class="col-11 col-md-8 col-lg-6">
      <el-input
        v-for="item in conti"
        :key="item.pkConto"
        :model-value="'dashboard.amanthy.com/register' + '?ref=' + item.codice"
        class="input-url"
        :disabled="true"
      >
        <template #prepend v-if="device != 'mobile'"> URL </template>
        <template #append v-if="device != 'mobile'">
          <el-button
            @click="
              doCopy('dashboard.amanthy.com/register' + '?ref=' + item.codice)
            "
            type="success"
            ><i class="el-icon-link"></i> Copia</el-button
          >
        </template>
      </el-input>
    </div>
  </div>
  <div class="row justify-content-center mb-3 " v-if="device === 'mobile'">
      <div class="col-5 text-center">
        <el-button
        v-for="item in conti"
        :key="item.pkConto"
          size="medium"
          @click="doCopy('dashboard.amanthy.com/register' + '?ref=' + item.codice)"
          type="primary"
          ><i class="el-icon-link"></i> Copia</el-button
        >
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import RefID from "../../components/Link/RefID.vue";
import { copyText } from "vue3-clipboard";
import { FetchConti } from "@/api/fetchData.js";
export default {
  components: { RefID },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      conti: null,
      fetchReferralError: false,
      loadingCard: true,
    };
  },
  created() {
    this.loadConti();
  },
  methods: {
    tryAgain() {
      this.fetchAddress().then(() => {
        if (this.address == []) {
          this.fetchAddressError = false;
        } else {
          this.fetchAddressError = true;
        }
      });
    },
    doCopy(url) {
      copyText(url, undefined, (error) => {
        if (error) {
          this.$message.error("Impossibile copiare");
        } else {
          this.$message({
            message: "Copiato!",
            type: "success",
          });
        }
      });
    },
    async loadConti() {
      this.loadingCard = true;
      await FetchConti()
        .then((response) => {
          this.conti = response.data;
          this.url = response.data[0].url + "?ref=" + response.data[0].codice;
        })
        .catch(() => {
          this.fetchReferralError = true;
        });
      this.loadingCard = false;
    },
  },
};
</script>
<style>
.el-icon-link {
  font-size: 18px;
}
.input-url .el-input-group__prepend {
  background-color: white;
}
.input-url .el-input-group__append {
  background-color: rgba(0, 100, 255, 0.78);
  color: white;
}

.input-url {
  margin-bottom: 45px;
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
</style>
