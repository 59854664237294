<template>
  <!--:style="device==='mobile' ? 'height: 250px' : 'height: 170px'"-->
  <el-card
    class="box-card"
    shadow="never"
    :v-loading="loadingCard"
    v-for="item in referralID"
    :key="item.pkConto"
  >
    <div v-if="fetchReferralError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">
          Something went wrong please try again!
        </p>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-5 fs-6 fw-bold">Fornitore:</dt>
        <dd class="col-sm-7 fs-6">{{ item.fornitore }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">ReferralID:</dt>
        <dd class="col-sm-7 fs-6">{{ item.codice }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Descrizione:</dt>
        <dd class="col-sm-7 fs-6">{{ item.descrizione }}</dd>
      </dl>
    </div>
  </el-card>
</template>

<script>
import { mapState } from "vuex";
import { FetchConti } from "@/api/fetchData.js";
export default {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      referralID: null,
      loadingCard: false,
      fetchReferralError: false,
    };
  },

  created() {
    this.fetchReferral();
  },
  methods: {
    tryAgain() {
      this.fetchReferral().then(() => {
        if (this.referralID == null) this.fetchReferralError = false;
        else {
          this.fetchReferralError = true;
        }
      });
    },
    async fetchReferral() {
      this.loadingCard = true;
      await FetchConti()
        .then((response) => {
          this.referralID = response.data;
        })
        .catch(() => {
          this.fetchReferralError = true;
        });
      this.loadingCard = false;
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.box-card {
  margin-bottom: 25px;
}
.row {
  margin-bottom: 0px;
}

</style>